<template>
  <v-card flat>
    <v-card-text class="px-2">
      <v-expand-transition>
        <v-toolbar color="color2 color2Text--text mb-2" dense v-if="!useI">
          <v-toolbar-title>
            <v-icon class="mr-3" color="color2Text">fas fa-map-marker-alt</v-icon>Court {{court}} <v-icon class="ml-3 mr-1" color="color2Text">fas fa-clock</v-icon> Time: {{time}}
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar color="color2 color2Text--text mb-2" dense v-else-if="iCourt && iTime">
          <v-toolbar-title>
            <v-icon class="mr-3" color="color2Text">fas fa-map-marker-alt</v-icon>Court {{iCourt}} <v-icon class="ml-3 mr-1" color="color2Text">fas fa-clock</v-icon> Time: {{iTime | localDt}}
          </v-toolbar-title>
        </v-toolbar>
      </v-expand-transition>
      <v-window v-model="hWindow">
        <v-window-item key="a">
          <div class="title pt-3 pl-6">Choose Team 1</div>
        </v-window-item>
        <v-window-item key="b">
          <v-toolbar color="color2 color2Text--text mb-2" dense>
            <v-toolbar-title>
              <v-icon class="mr-3" color="color2Text">fas fa-user-friends</v-icon>
              <fit-text :key="homeTeamIds[0]">{{homeTeams.map(m => m.name) | formatArray}}</fit-text>
            </v-toolbar-title>
          </v-toolbar>
        </v-window-item>
      </v-window>
      <v-window v-model="aWindow" v-if="window >= 1">
        <v-window-item key="c">
          <div class="title pt-3 pl-6">Choose Team 2</div>
        </v-window-item>
        <v-window-item key="d">
          <v-toolbar color="color2 color2Text--text mb-2" dense>
            <v-avatar color="color2Text" class="vs elevation-9" size="36">
              <span class="color2--text font-weight-black">VS</span>
            </v-avatar>
            <v-icon class="mr-3" color="color2Text">fas fa-user-friends</v-icon><fit-text>{{awayTeams.map(m => m.name) | formatArray}}</fit-text>
          </v-toolbar>
        </v-window-item>
      </v-window>
      <v-window v-model="mWindow" v-if="window >= 2">
        <v-window-item key="h">
          <div class="title pt-3 pl-6">Choose The Match Settings</div>
        </v-window-item>
        <v-window-item key="i">
          <v-alert type="info" :value="false">
            {{matchDescription}}
          </v-alert>
          <v-toolbar color="color2 color2Text--text mb-2" dense>
              <v-icon class="mr-3" color="color2Text">fas fa-tally</v-icon><fit-text>{{matchDescription}}</fit-text>
          </v-toolbar>
        </v-window-item>
      </v-window>
    </v-card-text>
    <v-card-text class="grey lighten-4 px-0">
      <!-- Division & Search -->
      <v-row dense class="px-6">
        <v-col cols="12" md="6">
          <v-select
            :items="divisions"
            v-model="div"
            label="Division"
            color="color3"
            item-color="color3"
            attach
            v-if="tournament.publicDivisions.length > 1"
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            color="color3"
            append-icon="fas fa-search"
            label="Search"
            hide-details
            v-model="search"
            :solo-inverted="window <= 1"
            clearable
            :disabled="window > 1"
            v-if="window <= 1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-window v-model="window">
        <!-- HOME -->
        <v-window-item key="e">
          <v-col cols="12" class="pa-0">
            <v-data-iterator
              :items="teams"
              item-key="id"
              :items-per-page="-1"
              hide-default-footer
              :search="search"
            >
              <template v-slot:default="props">
                <v-slide-group
                  class="pa-1"
                  :multiple="kob"
                  show-arrows center-active
                >
                  <v-slide-item
                    v-for="item in props.items"
                    :key="item.team.id"
                  >
                    <v-card
                      width="200"
                      class="ma-1"
                      :color="homeTeamIds.includes(item.team.id) ? 'color3 ' : null"
                      @click="selectTeam(item.team, 'home')"
                    >
                      <muc-team
                        :item="item"
                        :selected="homeTeamIds.includes(item.team.id)"
                        :showDivision="!division"
                        :hideStats="true"
                        :time="time"
                      ></muc-team>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </template>
            </v-data-iterator>
          </v-col>
        </v-window-item>
        <!-- AWAY -->
        <v-window-item key="f">
          <v-col cols="12" class="pa-0">
            <v-data-iterator
              :items="teams"
              item-key="id"
              :items-per-page="-1"
              hide-default-footer
              :search="search"
            >
              <template v-slot:default="props">
                <v-slide-group
                  v-model="away"
                  class="pa-1"
                  show-arrows center-active
                >
                  <v-slide-item
                    v-for="item in props.items"
                    :key="item.team.id"
                    :disabled="homeTeamIds.includes(item.team.id)"
                  >
                    <v-card
                      width="200"
                      class="ma-1"
                      :color="awayTeamIds.includes(item.team.id) ? 'color3 ' : null"
                      @click="selectTeam(item.team, 'away')"
                      :disabled="homeTeamIds.includes(item.team.id)"
                    >
                      <muc-team
                        :item="item"
                        :selected="awayTeamIds.includes(item.team.id)"
                        :showDivision="!division"
                        :showVs="!homeTeamIds.includes(item.team.id)"
                      ></muc-team>
                    </v-card>
                  </v-slide-item>
                </v-slide-group>
              </template>
            </v-data-iterator>
          </v-col>
        </v-window-item>
        <!-- MATCH -->
        <v-window-item key="g">
          <v-row dense class="px-6">
            <v-col cols="12" md="6" class="py-0">
              <v-card>
                <v-toolbar color="color1 color1Text--text">
                  <v-toolbar-title>Match Settings</v-toolbar-title>
                </v-toolbar>
                <match-settings-editor
                  v-if="matchSettings"
                  :setting="matchSettings"
                  :edit="true"
                  :notime="!useI"
                ></match-settings-editor>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense class="px-6" v-if="useI">
            <v-col cols="12" md="6" class="py-0 pt-2">
              <v-card>
                <v-toolbar color="color1 color1Text--text">
                  <v-toolbar-title>Court & Time</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-alert type="info" v-if="duals" text>
                    Please Enter {{ dualPairCount }} Courts
                  </v-alert>
                  <v-combobox
                    v-if="duals"
                    v-model="iCourts"
                    :items="tournament.courts"
                    label="Assign a court"
                    persistent-hint
                    hint="Select a court or enter a new one"
                    color="color3"
                    item-color="color3"
                    multiple chips small-chips deletable-chips
                  ></v-combobox>
                  <v-combobox
                    v-else
                    v-model="iCourt"
                    :search-input.sync="iCourt"
                    :items="tournament.courts"
                    label="Assign a court"
                    persistent-hint
                    hint="Select a court or enter a new one"
                    color="color3"
                    item-color="color3"
                    clearable
                  ></v-combobox>
                  <date-time-picker
                    :datetime.sync="iTime"
                    label="Start"
                  ></date-time-picker>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-window-item>
        <!-- DUALS -->
        <v-window-item key="h">
          <v-row dense class="px-6">
            <v-col cols="12" md="6" class="py-0">
              <v-card>
                <v-toolbar color="color1 color1Text--text">
                  <v-toolbar-title>Dual Courts & Times</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <div v-for="n in dualPairCount" :key="`d-${n}`">
                    <v-select
                      :items="availSlots || calcSlots"
                      v-model="slots[n-1]"
                      :label="`Pair ${n} Court & Time`"
                      return-object
                    >
                    </v-select>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-window-item>
        <!-- SAVE -->
        <v-window-item key="j">
          <v-row dense class="px-6">
            <v-col cols="12" class="py-0 text-center title">
              <div>Please confirm all the information above and click save</div>
              <v-btn
                color="success white--text"
                class="pa-6"
                :loading="loading"
                @click.stop="save"
              >
                <v-icon class="mr-3">fas fa-save</v-icon> save and Add Match
              </v-btn>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>

    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-fab-transition>
        <v-btn
          color="color3 color3Text--text"
          fab
          @click.stop="prev"
          v-if="window > 0"
          :disabled="loading"
        >
          <v-icon>fas fa-caret-left</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          :key="valid[this.window]"
          color="success white--text"
          v-if="window < 4"
          fab
          :disabled="!valid[this.window]"
          @click.stop="next"
        >
          <v-icon>fas fa-caret-right</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import MatchSettingsEditor from '@/components/Tournament/RoundSettings/MatchSettings.vue'
import MatchSettings from '@/classes/MatchSettings'
import PoolPlayOptionDescriptor from '@/classes/PoolPlayOptionDescriptor'
import MucTeam from './MucTeam'
import moment from 'moment'
import max from 'lodash.max'
import flatten from '../../../helpers/ArrayFlatten'
const DateTimePicker = () => import('@/components/Utils/DateTimePicker.vue')

export default {
  props: ['day', 'court', 'time', 'open', 'availSlots', 'roundId'],
  data () {
    return {
      div: null,
      search: null,
      window: 0,
      home: [],
      away: [],
      ref: null,
      kob: false,
      hWindow: 0,
      aWindow: 0,
      mWindow: 0,
      matchSettings: null,
      loading: false,
      slots: [],
      iTime: null,
      iCourt: null,
      iCourts: []
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    duals () {
      return this.homeDivision && this.homeDivision.isDuals
    },
    dualPairCount () {
      return this.homeDivision && this.homeDivision.dualPairCount
    },
    teams () {
      const m = this.tournament.fullTimeline
      var base = flatten(this.tournament.divisions.map(d => {
        return d.activeTeams.map(t => {
          return {
            div: d,
            team: t,
            name: t.name,
            matches: {
              sameCourt: m.filter(f => f.court === this.court && ((f.homeTeam && f.homeTeam.id === t.id) || (f.awayTeam && f.awayTeam.id === t.id))),
              sameDay: m.filter(f => f.day === this.day && ((f.homeTeam && f.homeTeam.id === t.id) || (f.awayTeam && f.awayTeam.id === t.id))),
              sameTime: m.filter(f => f.time === this.time && ((f.homeTeam && f.homeTeam.id === t.id) || (f.awayTeam && f.awayTeam.id === t.id))),
              sameUnix: m.filter(f => f.day === this.day && f.time === this.time && ((f.homeTeam && f.homeTeam.id === t.id) || (f.awayTeam && f.awayTeam.id === t.id))),
              total: m.filter(f => ((f.homeTeam && f.homeTeam.id === t.id) || (f.awayTeam && f.awayTeam.id === t.id))),
              vs: this.homeTeamIds.length ? m.filter(f => ((f.homeTeam && f.homeTeam.id === this.homeTeamIds[0]) || (f.awayTeam && f.awayTeam.id === this.homeTeamIds[0])) && ((f.homeTeam && f.homeTeam.id === t.id) || (f.awayTeam && f.awayTeam.id === t.id))) : []
            }
          }
        })
      }))
      if (this.division) {
        base = base.filter(f => f.div.id === this.division.id)
      }
      return base
    },
    useTeamNames () {
      return this.division ? (this.division.numOfPlayers > 2 || this.division.useTeamNames) : true
    },
    homeTeams () {
      return this.home
    },
    homeDivision () {
      return this.homeTeamIds.length && this.tournament.divisions.find((d) => {
        return d.teams.find((t) => {
          return t.id === this.homeTeamIds[0]
        })
      })
    },
    homeTeamIds () {
      return this.home.map(m => m.id)
    },
    awayTeams () {
      return this.away
    },
    awayTeamIds () {
      return this.away.map(m => m.id)
    },
    division () {
      return (this.div && this.tournament.publicDivisions.find(f => f.id === this.div)) || (this.tournament.publicDivisions.length === 1 && this.tournament.publicDivisions[0])
    },
    divisionId () {
      if (this.roundId) {
        const d = this.tournament.getDivisionByRoundId(this.roundId)
        return d && d.id
      }
      return null
    },
    divisions () {
      const d = this.tournament.publicDivisions.map(d => {
        return {
          text: d.name,
          value: d.id
        }
      })
      return this.divisionId ? d.filter(f => f.value === this.divisionId) : [
        { text: 'All', value: null },
        ...d
      ]
    },
    validCourts () {
      return this.iCourts.length === this.dualPairCount
    },
    calcSlots () {
      return this.iTime && this.iCourts && this.iCourts.map(m => {
        const t = moment(this.iTime).format('LT')
        return {
          court: m,
          time: t,
          text: `Court ${m} @ ${t}`
        }
      })
    },
    valid () {
      return [
        this.homeTeamIds.length > 0,
        this.awayTeamIds.length > 0,
        !!this.matchSettings && ((this.court && this.time) || (this.iCourt && this.iTime) || (this.validCourts && this.iTime)),
        this.slots.length === this.dualPairCount
      ]
    },
    round () {
      if (this.roundId) {
        return this.homeDivision && this.homeDivision.rounds.find(f => f.id === this.roundId)
      }
      return this.homeDivision && this.homeDivision.rounds.find(f => f.date.format('MMM Do YYYY') === this.day)
    },
    pool () {
      return this.round && this.round.flight.pools[0]
    },
    useI () {
      return !(this.court && this.time)
    },
    startTime () {
      if (this.useI) return moment(this.iTime).format('YYYY-MM-DD[T]HH:mm')
      return moment(`${this.day} ${this.time}`, 'MMM Do YYYY h:mm A').format('YYYY-MM-DD[T]HH:mm')
    },
    iDay () {
      return this.iTime && moment(this.iTime).format('MMM Do YYYY')
    },
    a () {
      return this.round && this.round.flight && this.round.flight
    },
    currentMatchSettings () {
      return this.round && this.round.flight && this.round.flight.settings.length > 0 && this.round.flight.settings[0]
    },
    matchDescription () {
      var d = this.matchSettings && new PoolPlayOptionDescriptor(this.matchSettings)
      return d && d.description
    },
    dto () {
      return this.pool && this.matchSettings && {
        poolId: this.pool.id,
        number: this.pool.matches.length ? max(this.pool.matches.map(m => m.number)) + 1 : 1,
        court: this.useI ? this.iCourt : this.court,
        homeTeamIds: this.homeTeamIds,
        awayTeamIds: this.awayTeamIds,
        // refTeam: this.ref ? this.ref.id : null,
        startTime: this.startTime,
        isMatch: this.matchSettings.matchType.isMatch,
        // games: this.settings.dto.gameSettings,
        settings: this.matchSettings.dto,
        manualRefId: null,
        id: 0,
        bracketId: null
      }
    },
    dualDto () {
      if (this.pool && this.matchSettings && this.duals) {
        const n = (this.pool.matches.length ? max(this.pool.matches.map(m => Math.floor(m.number / 1000))) + 1 : 1) * 1000
        return this.slots.map((m, i) => {
          return {
            poolId: this.pool.id,
            number: n + i + 1,
            court: m.court,
            homeTeamIds: this.homeTeamIds,
            awayTeamIds: this.awayTeamIds,
            startTime: moment(`${this.day || this.iDay} ${m.time}`, 'MMM Do YYYY h:mm A').format('YYYY-MM-DD[T]HH:mm'),
            isMatch: this.matchSettings.matchType.isMatch,
            settings: this.matchSettings.dto,
            manualRefId: null,
            id: 0,
            bracketId: null
          }
        })
      }
      return null
    }
  },
  methods: {
    selectTeam (t, which) {
      if (which === 'home') {
        this.home = [t]
      } else {
        this.away = [t]
      }
      this.next()
    },
    next () {
      this.search = null
      if (this.window === 0 && this.div === null) {
        this.div = this.homeDivision.id
      }
      if (this.window === 2 && this.mWindow === 0) this.mWindow++
      this.window++
      if (this.window === 3 && !this.duals) this.window++
    },
    prev () {
      if (this.window === 2) this.mWindow--
      this.window--
      if (this.window === 3 && !this.duals) this.window--
    },
    reset () {
      this.div = this.divisionId
      this.search = null
      this.window = 0
      this.home = []
      this.away = []
      this.ref = null
      this.kob = false
      this.hWindow = 0
      this.aWindow = 0
      this.matchSettings = new MatchSettings({
        poolsOf: 0,
        matchTypeId: 1
      }, true, true)
      this.slots = []
      if (this.court && this.time && this.availSlots) {
        const s = this.availSlots.find(f => f.court === this.court && f.time === this.time)
        s && this.slots.push(s)
      }
      if (this.court) this.iCourt = this.court
      if (this.time) this.iTime = this.time
    },
    save () {
      this.loading = true
      const dto = this.duals ? this.dualDto : [this.dto]
      this.$VBL.post.matches(dto, true)
        .then(r => {
          this.$emit('saved')
        })
        .catch(err => console.log(err.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    kob: function (val) {
      if (val) {
        if (this.home >= 0) {
          this.home = [this.home]
        }
        if (this.away >= 0) {
          this.away = [this.away]
        }
      } else {
        if (this.home.length) {
          this.home = this.home[0]
        }
        if (this.away.length) {
          this.away = this.away[0]
        }
      }
    },
    'homeTeams.length': function (v) {
      this.hWindow = v ? 1 : 0
    },
    'awayTeams.length': function (v) {
      this.aWindow = v ? 1 : 0
    },
    open: 'reset',
    currentMatchSettings: function (v) {
      if (!v) return
      this.matchSettings = new MatchSettings({
        poolsOf: 0,
        matchTypeId: v.matchTypeId || 1
      }, true, true)
    },
    loading: function (v) {
      this.$emit('loading-change', v)
    },
    'calcSlots.length': function (v) {
      this.slots = [...this.calcSlots]
    },
    iTime: function (v) {
      this.slots = [...this.calcSlots]
    },
    iCourts: function (v) {
      this.slots = [...this.calcSlots]
    }
  },
  mounted () {
    this.reset()
  },
  components: {
    MatchSettingsEditor,
    MucTeam,
    DateTimePicker
  }
}
</script>

<style scoped>
.vs {
  position: absolute;
  margin-left: 100px;
  margin-top: -58px;
}
.badge {
  position: absolute;
  top: calc(100% - 7px);
  left: 7px;
}
</style>
