<template>
  <v-card class="flexcard fill-height" flat :color="selected ? 'color3' : ''">
    <v-card-text :class="selected ? 'color3Text--text' : ''" class="grow">
      <template v-if="showDivision">
        <div class="font-weight-bold">{{item.div.name}}</div>
        <v-divider></v-divider>
      </template>
      <div v-if="useLogos" class="d-flex justify-center">
        <v-img
          :src="item.team.logo || 'https://vblimages.blob.core.windows.net/images/fe5a48c3-072d-4f4e-ab79-38c224439fcf.png'"
          height="36"
          max-width="48"
          contain
        ></v-img>
      </div>
      <div v-if="showTeamName" :class="{ 'font-weight-bold': showPlayers }">
        <fit-text>{{item.team.name}}</fit-text>
      </div>
      <template v-if="showPlayers">
        <div v-for="p in item.team.players" :key="p.id" :class="selected ? 'color3Text--text' : ''">
          <fit-text>{{p.name}}</fit-text>
        </div>
      </template>
    </v-card-text>
    <v-card-text class="pb-0" :class="selected ? 'color3Text--text' : ''">
      <div class="font-weight-bold">Matches</div>
      <v-divider></v-divider>
      <template v-if="time">
        <div v-if="item.matches.sameUnix.length > 0" class="error--text">Already Playing</div>
        <div v-else class="success--text">Available</div>
      </template>
      <div>Today: {{item.matches.sameDay.length}}</div>
      <div>Total: {{item.matches.total.length}}</div>
      <div v-if="!hideStats">Time Slot: {{item.matches.sameTime.length}}</div>
      <div v-if="!hideStats">Court: {{item.matches.sameCourt.length}}</div>
      <div v-if="showVs">VS Team 1: {{item.matches.vs.length}}</div>
      <v-badge color="primary" v-if="item.team.seed">
        <span slot="badge">{{item.team.seed}}</span>
      </v-badge>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['item', 'selected', 'showDivision', 'showVs', 'hideStats', 'time'],
  computed: {
    showTeamName () {
      return this.item.div.numOfPlayers > 2 || this.item.div.useTeamNames || this.item.team.players.length === 0 || this.item.div.isNcaa
    },
    showPlayers () {
      return !this.showTeamName // || this.item.div.isNcaa
    },
    useLogos () {
      return this.item.div.useLogos
    }
  }
}
</script>
